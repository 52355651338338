import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// 解决vue路由重复导航错误
// 获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
// 修改原型对象中的push方法
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

export const routes = [
  {
    path: '/',
    redirect: '/homePage'
  },
  {
    path: '/homePage',
    name: 'HomePage',
    component: () => import('@/views/homePage'),
    meta: {
      title: '首页'
    }
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: () => import('@/views/aboutUs'),
    meta: {
      title: '走进我们',
    },
    children: [
      {
        path: '/aboutUs/company',
        name: 'Company',
        redirect: '/aboutUs',
        meta: {
          title: '公司介绍'
        }
      },
      {
        path: '/aboutUs/culture',
        name: 'Culture',
        redirect: '/aboutUs',
        meta: {
          title: '企业文化'
        }
      },
			{
			  path: '/aboutUs/team',
			  name: 'Team',
			  redirect: '/aboutUs',
			  meta: {
			    title: '关于团队'
			  }
			},
      {
        path: '/aboutUs/development',
        name: 'Development',
        redirect: '/aboutUs',
        meta: {
          title: '研发和生产基地'
        }
      },
			{
			  path: '/aboutUs/certificate',
			  name: 'Certificate',
			  redirect: '/aboutUs',
			  meta: {
			    title: '荣誉资质'
			  }
			},
      /* {
        path: '/aboutUs/platform',
        name: 'Platform',
        redirect: '/aboutUs',
        meta: {
          title: '职业提升平台'
        }
      }, */
      /* {
        path: '/aboutUs/technology',
        name: 'Technology',
        redirect: '/aboutUs',
        meta: {
          title: '技术与团队'
        }
      } */
    ]
  },
  {
    path: '/aboutUs/team/detail',
    name: 'TeamDetail',
    component: () => import('@/views/aboutUs/team/detail'),
    meta: {
      title: '管理团队详情'
    }
  },
	
	{
	  path: '/serviceCenter',
	  name: 'ServiceCenter',
	  component: () => import('@/views/serviceCenter'),
	  meta: {
	    title: '服务中心'
	  },
		children: [
			{
			  path: '/serviceCenter/screening-service',
			  name: 'Screening-service',
			  redirect: '/serviceCenter',
			  meta: {
			    title: '筛选服务'
			  }
			},
			{
			  path: '/serviceCenter/customized-service',
			  name: 'Customized-service',
			  redirect: '/serviceCenter',
			  meta: {
			    title: '定制服务'
			  }
			},
		]
	},
  {
    path: '/serviceCenter/price',
    name: 'ProductPrice',
    component: () => import('@/views/serviceCenter/productPrice'),
    meta: {
      title: '服务中心询价中心'
    }
  },
	
  {
		path: '/productCenter',
		name: 'productCenter',
		component: () => import('@/views/productCenter/product'),
		meta: {
			title: '产品中心'
		},
		children: [
			{
				path: '/productCenter/sp',
				name: 'ProductSp',
				redirect: '/productCenter',
				meta: {
					title: 'SadPhos手性配体'
				}
			},
			{
				path: '/productCenter/ecl',
				name: 'ProductEcl',
				redirect: '/productCenter',
				meta: {
					title: '恶唑啉手性配体'
				}
			},
			{
				path: '/productCenter/emt',
				name: 'ProductEmt',
				redirect: '/productCenter',
				meta: {
					title: '二茂铁手性配体'
				}
			},
			{
				path: '/productCenter/sa',
				name: 'ProductSa',
				redirect: '/productCenter',
				meta: {
					title: '手性双胺配体'
				}
			},
			{
				path: '/productCenter/kb',
				name: 'ProductKb',
				redirect: '/productCenter',
				meta: {
					title: '手性卡宾配体'
				}
			},
			{
				path: '/productCenter/ls',
				name: 'ProductLs',
				redirect: '/productCenter',
				meta: {
					title: '手性磷酸'
				}
			},
			{
				path: '/productCenter/qt',
				name: 'ProductQt',
				redirect: '/productCenter',
				meta: {
					title: '其它'
				}
			},
		]
  },
	{
	  path: '/productCenter/list',
	  name: 'ProductList',
	  component: () => import('@/views/productCenter/productList'),
	  meta: {
	    title: '产品中心搜索列表'
	  }
	},
	{
		path: '/productCenter/detail',
		name: 'ProductDetail',
		component: () => import('@/views/productCenter/detail'),
		meta: {
			title: '产品详情'
		}
	},
	
	{
		path: '/newsCenter',
		name: 'newsCenter',
		component: () => import('@/views/newsCenter/index'),
		meta: {
			title: '新闻中心'
		},
		children: [
			{
				path: '/newsCenter/company',
				name: 'companyNews',
				redirect: '/newsCenter',
				meta: {
					title: '公司新闻'
				}
			},
			{
				path: '/newsCenter/profession',
				name: 'professionNews',
				redirect: '/newsCenter',
				meta: {
					title: '行业资讯'
				}
			},
		]
	},
	{
		path: '/newsCenter/detail',
		name: 'newsDetail',
		component: () => import('@/views/newsCenter/detail'),
		meta: {
			title: '新闻详情'
		}
	},
	
	{
		path: '/contact',
		name: 'contact',
		component: () => import('@/views/contact/index'),
		meta: {
			title: '联系我们'
		},
		children: [
			{
				path: '/contact/way',
				name: 'contactWay',
				redirect: '/contact',
				meta: {
					title: '联系方式'
				}
			},
			{
				path: '/contact/join',
				name: 'contactJoin',
				redirect: '/contact',
				meta: {
					title: '加入我们'
				}
			},
			{
				path: '/contact/message',
				name: 'contactMessage',
				redirect: '/contact',
				meta: {
					title: '留言板'
				}
			},
		]
	},
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

export default router
